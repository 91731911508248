<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getSkuInventoryReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-8">
              <h2 class="font-weight-normal">
                {{ $t('management.txtreportBySKU') }}
              </h2>
              <show-date></show-date>
            </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton
                id="SKUInventoryExportButton"
                v-on:click="exportSKUInventoryReport()"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
        
          <search-pattern
            class="mb-4"
            :keyword="keyword"
            :searchBy="searchBy"
            v-on:getSort="setSearch"
          ></search-pattern>
          
            <CRow>
            <CCol sm="12" lg="12">
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              />
              
              <hr />
              <DataTable
                id="SKUInventoryDatatable"
                :items="items"
                :fields="fields"
                hover
                border
                style="white-space:nowrap"
              >
                <template #id="{ index }">
                  <td :id="'SKUInventoryDatatableItem'+index" class="text-dark" style="width: 2%">{{ itempage + index + 1 }}</td>
                </template>
                <template #unit="{item}">
                  <td class="text-dark" style="vertical-align: middle;">{{ item.unit }}</td>
                </template>
                <template #onhandQty="{ item }">
                  <td v-if="floatValue(item.onhandQty) < 0.00" class="text-right font-weight-normal text-danger">{{ item.onhandQty }}</td>
                  <td v-else class="text-right font-weight-normal text-dark">{{ item.onhandQty }}</td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getSkuInventoryReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import SearchPattern from '@/containers/SearchPattern'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
    SearchPattern,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      searchLoadingButton: true,
      keyword: '',
      searchBy: '',
      skuType: '',
      sortdata: 'SKUName',
      lastupdate: '',
      sort_value: {
        name: 'SKUName',
        category: 'category.name',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
        PLUCode: 'ProductPLU.PLUCode',
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'permissions']),
    itempage() {
      return this.meta_data.current_page * 50 - 50
    },
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'id', label: this.$i18n.t(' # '), _classes: 'text-center text-dark font-weight-normal' },
        { key: 'PLUCode', label: this.$i18n.t('pluCode'), _style: 'width:15%', _classes: "text-dark  font-weight-normal"},
        {
          key: 'name',
          label: this.$i18n.t('productName'),
          _style: 'width:40%',
          _classes: 'font-weight-normal text-dark'
        },
        { key: 'unit', label: this.$i18n.t('unit'), _classes: 'font-weight-normal text-dark ' },
        { key: 'categoryName', label: this.$i18n.t('category'), _classes: 'font-weight-normal text-dark ' },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      const data = this.data
      let detail = []
      let start = this.meta_data.current_page * 20 - 20
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const SKUName = item.SKUName || ''
        
        // Parse data Category Name
        let categoryName = ''
        let category = item.category || null
        if (category != null) {
          categoryName = category.name || 'N/A'
        } else {
          category = item.Category || null
          if (category != null) {
            categoryName = category.name || 'N/A'
          }
        }
        
        // Parse unit data.
        const unit = item.unit || {}
        const unitName = unit.name || 'N/A'

        // Parse PLU Code
        const productPLU = item.ProductPLU || {}
        const PLUCode = productPLU.PLUCode || ''
        
        let onhandQty = ''
        if (item.skuType === 'SV' || item.skuType === 'BOM' || null) {
          // Product Type checked.
          onhandQty = this.$t('nonStock')
        } else {
          const productSKUStock = item.ProductSKUStock 
          if (productSKUStock == null) {
            onhandQty = 'No Stock Data'
          } else {
            // Not existing Product SKU Stock
            onhandQty = util.convertOnhand(productSKUStock.onhandQty || 0)
          }
        }

        detail.push({
            id: start + i + 1,
            PLUCode: PLUCode,
            name: SKUName,
            unit: unitName,
            categoryName: categoryName,
            onhandQty: onhandQty,
          })
      }
      return detail
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'ProductSKUStock.onhandQty'
        }
        if (sortdata == 'sortByNumMin' || sortdata == 'sortByNumMax') {
          sortdata = 'SKUPrice'
        }

        
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
  },
  created() {
    this.getSkuInventoryReport()
  },
  methods: {
    setSearch(searchBy, keyword) {
      this.keyword = keyword
      this.searchBy = searchBy
      this.getSkuInventoryReport()
    },
    setSort(value) {
      this.sortdata = value
      this.getSkuInventoryReport()
    },
    getSkuInventoryReport(page = 1) {
      this.loadingButton = false
      this.searchLoadingButton = false

      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }

      let sortOrder = ''
      if (this.sortdata == 'sortByNumMin' || this.sortdata == 'onhandQtyMin') {
        sortOrder = 'asc'
      } else if (
        this.sortdata == 'sortByNumMax' ||
        this.sortdata == 'onhandQtyMax'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }

      let params = {
          shopObjectId: shopObjectId,
          page: page,
          limit: 50,
          sortattr: this.sortAttr,
          sortorder: sortOrder,
      }

      // Search value.
      if (this.searchBy !== '') {
        params['searchVal'] = this.keyword
        params['searchAttr'] = this.searchBy
      } 

      axios({
        url: '/inventory/v2.0/get/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.searchLoadingButton = true
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportSKUInventoryReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan

      const params = {
        shopObjectId: shopObjectId,
        plan: plan,
      }
      const headers = { shopObjectId: shopObjectId }

      report({
        url: '/inventory/v1.0/get/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสินค้าคงเหลือตามSKU.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
